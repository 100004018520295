'use strict'

import Vue from 'vue'
import Vuex from 'vuex'

import LoginService from "./services/loginService";
import MenuService from "./services/menuService";
import SanctumService from "./services/sanctumService";

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        layout: 'LoginLayout',
        user: null,
        menu: null,
        campus: null,
    },
    mutations: {
        SET_LAYOUT(state, newLayout) {
            state.layout = newLayout
        },
        SET_LOGIN_STATE(state, loginState) {
            state.is_loged = loginState;
        },
        SET_LOGIN_USER(state, user) {
            state.user = user;
        },
        SET_USER_MENU(state, menu) {
            state.menu = menu;
        },
        SET_CAMPUS(state, campus) {
            state.campus = campus;
        }
    },
    actions: {
        async login({ dispatch, commit }, credentials) {
            await SanctumService.csrfCookie();
            await LoginService.authenticate(credentials).then(response => {
                if (response.data) {
                    commit('SET_LOGIN_USER', response.data);
                    return dispatch("getMenu");
                }
            });
        },
        async logout({ commit }) {
            await SanctumService.csrfCookie();
            await LoginService.logout();
            commit('SET_LOGIN_USER', {});
        },
        getMenu({ commit }) {
            MenuService.getAuthenticatedUserMenu().then(response => {
                commit("SET_USER_MENU", response.data);
            });
        },
        async setCampus({ commit }, campus) {
            commit("SET_CAMPUS", campus);
        }
    }
})
