<template>
    <div>
        <nav class="topnav navbar navbar-expand shadow navbar-light bg-white" id="sidenavAccordion">
            
            <div  class="navbar-brand text-center" >
                <img  src="@/assets/img/sinusalud.png" style="width: auto; height: 30px;">
            </div>
            <button class="btn btn-icon btn-transparent-dark order-1 order-lg-0 mr-lg-2" id="sidebarToggle" href="#">
                <i class="fas fa-bars"></i>
            </button>
            <!--       <form class="form-inline mr-auto d-none d-md-block">
                    <div class="input-group input-group-joined input-group-solid">
                      <input
                        class="form-control mr-sm-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <i data-feather="search"></i>
                        </div>
                      </div>
                    </div>
                  </form> -->
            <ul class="navbar-nav align-items-center ml-auto">
                <li class="nav-item dropdown no-caret mr-3 d-none d-md-inline small font-weight-bolder" v-if="campus">
                    <i class="fas fa-map-marker-alt fa-fw"></i>
                    {{campus.NOMBRE_SEDE}}
                </li>
                <!-- <li class="nav-item dropdown no-caret mr-3 d-none d-md-inline">
                  <a
                    class="nav-link dropdown-toggle"
                    id="navbarDropdownDocs"
                    href="javascript:void(0);"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div class="d-none d-md-inline font-weight-500">Documentation</div>
                    <i class="fas fa-chevron-right dropdown-arrow"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right py-0 mr-sm-n15 mr-lg-0 o-hidden animated--fade-in-up"
                    aria-labelledby="navbarDropdownDocs"
                  >
                    <a
                      class="dropdown-item py-3"
                      href="https://docs.startbootstrap.com/sb-admin-pro"
                      target="_blank"
                    >
                      <div class="icon-stack bg-primary-soft text-primary mr-4">
                        <i data-feather="book"></i>
                      </div>
                      <div>
                        <div class="small text-gray-500">Documentation</div>
                        Usage instructions and reference
                      </div>
                    </a>
                    <div class="dropdown-divider m-0"></div>
                    <a
                      class="dropdown-item py-3"
                      href="https://docs.startbootstrap.com/sb-admin-pro/components"
                      target="_blank"
                    >
                      <div class="icon-stack bg-primary-soft text-primary mr-4">
                        <i data-feather="code"></i>
                      </div>
                      <div>
                        <div class="small text-gray-500">Components</div>
                        Code snippets and reference
                      </div>
                    </a>
                    <div class="dropdown-divider m-0"></div>
                    <a
                      class="dropdown-item py-3"
                      href="https://docs.startbootstrap.com/sb-admin-pro/changelog"
                      target="_blank"
                    >
                      <div class="icon-stack bg-primary-soft text-primary mr-4">
                        <i data-feather="file-text"></i>
                      </div>
                      <div>
                        <div class="small text-gray-500">Changelog</div>
                        Updates and changes
                      </div>
                    </a>
                  </div>
                </li> -->
                <!-- <li class="nav-item dropdown no-caret mr-3 d-md-none">
                    <a
                        class="btn btn-icon btn-transparent-dark dropdown-toggle"
                        id="searchDropdown"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i data-feather="search"></i>
                    </a>

                    <div
                        class="dropdown-menu dropdown-menu-right p-3 shadow animated--fade-in-up"
                        aria-labelledby="searchDropdown"
                    >
                        <form class="form-inline mr-auto w-100">
                            <div class="input-group input-group-joined input-group-solid">
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Search for..."
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                />
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <i data-feather="search"></i>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>
                <li class="nav-item dropdown no-caret mr-3 dropdown-notifications">
                    <a
                        class="btn btn-icon btn-transparent-dark dropdown-toggle"
                        id="navbarDropdownAlerts"
                        href="javascript:void(0);"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i data-feather="bell"></i>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
                        aria-labelledby="navbarDropdownAlerts"
                    >
                        <h6 class="dropdown-header dropdown-notifications-header">
                            <i class="mr-2" data-feather="bell"></i>
                            Alerts Center
                        </h6>
                        <a class="dropdown-item dropdown-notifications-item" href="#!">
                            <div class="dropdown-notifications-item-icon bg-warning">
                                <i data-feather="activity"></i>
                            </div>
                            <div class="dropdown-notifications-item-content">
                                <div class="dropdown-notifications-item-content-details">
                                    December 29, 2019
                                </div>
                                <div class="dropdown-notifications-item-content-text">
                                    This is an alert message. It's nothing serious, but it
                                    requires your attention.
                                </div>
                            </div>
                        </a>
                        <a class="dropdown-item dropdown-notifications-item" href="#!">
                            <div class="dropdown-notifications-item-icon bg-info">
                                <i data-feather="bar-chart"></i>
                            </div>
                            <div class="dropdown-notifications-item-content">
                                <div class="dropdown-notifications-item-content-details">
                                    December 22, 2019
                                </div>
                                <div class="dropdown-notifications-item-content-text">
                                    A new monthly report is ready. Click here to view!
                                </div>
                            </div>
                        </a>
                        <a class="dropdown-item dropdown-notifications-item" href="#!">
                            <div class="dropdown-notifications-item-icon bg-danger">
                                <i class="fas fa-exclamation-triangle"></i>
                            </div>
                            <div class="dropdown-notifications-item-content">
                                <div class="dropdown-notifications-item-content-details">
                                    December 8, 2019
                                </div>
                                <div class="dropdown-notifications-item-content-text">
                                    Critical system failure, systems shutting down.
                                </div>
                            </div>
                        </a>
                        <a class="dropdown-item dropdown-notifications-item" href="#!">
                            <div class="dropdown-notifications-item-icon bg-success">
                                <i data-feather="user-plus"></i>
                            </div>
                            <div class="dropdown-notifications-item-content">
                                <div class="dropdown-notifications-item-content-details">
                                    December 2, 2019
                                </div>
                                <div class="dropdown-notifications-item-content-text">
                                    New user request. Woody has requested access to the
                                    organization.
                                </div>
                            </div>
                        </a>
                        <a class="dropdown-item dropdown-notifications-footer" href="#!"
                        >View All Alerts</a
                        >
                    </div>
                </li>
                <li class="nav-item dropdown no-caret mr-3 dropdown-notifications">
                    <a
                        class="btn btn-icon btn-transparent-dark dropdown-toggle"
                        id="navbarDropdownMessages"
                        href="javascript:void(0);"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i data-feather="mail"></i>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
                        aria-labelledby="navbarDropdownMessages"
                    >
                        <h6 class="dropdown-header dropdown-notifications-header">
                            <i class="mr-2" data-feather="mail"></i>
                            Message Center
                        </h6>
                        <a class="dropdown-item dropdown-notifications-item" href="#!">
                            <img
                                class="dropdown-notifications-item-img"
                                :src="user_icon"
                                alt="Default User Image"
                            />
                            <div class="dropdown-notifications-item-content">
                                <div class="dropdown-notifications-item-content-text">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum
                                    dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                    cupidatat non proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </div>
                                <div class="dropdown-notifications-item-content-details">
                                    Emily Fowler · 58m
                                </div>
                            </div>
                        </a>
                        <a class="dropdown-item dropdown-notifications-item" href="#!">
                            <img
                                class="dropdown-notifications-item-img"
                                :src="user_icon"
                            />
                            <div class="dropdown-notifications-item-content">
                                <div class="dropdown-notifications-item-content-text">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum
                                    dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                    cupidatat non proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </div>
                                <div class="dropdown-notifications-item-content-details">
                                    Diane Chambers · 2d
                                </div>
                            </div>
                        </a>
                        <a class="dropdown-item dropdown-notifications-footer" href="#!"
                        >Read All Messages</a
                        >
                    </div>
                </li> -->
                <li class="nav-item dropdown no-caret mr-2 dropdown-user">
                    <a
                        class="btn btn-icon btn-transparent-dark dropdown-toggle"
                        id="navbarDropdownUserImage"
                        href="javascript:void(0);"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <img
                            class="img-fluid"
                            src="@/assets/img/default-user-image60x60.png"
                            alt="user-img"
                        />
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
                        aria-labelledby="navbarDropdownUserImage"
                    >
                        <h6 class="dropdown-header d-flex align-items-center">
                            <img
                                class="dropdown-user-img"
                                src="@/assets/img/default-user-image60x60.png"
                                alt="user-img"
                            />
                            <div class="dropdown-user-details">
                                <div class="dropdown-user-details-name">{{ user.name }}</div>
                                <div class="dropdown-user-details-email">{{ user.email }}</div>
                            </div>
                        </h6>
                        <div class="dropdown-divider"></div>
                        <!-- <a class="dropdown-item" href="#!">
                          <div class="dropdown-item-icon">
                            <i data-feather="settings"></i>
                          </div>
                          Account
                        </a> -->
                        <router-link class="dropdown-item" to="/seleccionar-sede">
                            <div class="dropdown-item-icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            Seleccionar Sede
                        </router-link>
                        <a class="dropdown-item" @click="logout()">
                            <div class="dropdown-item-icon">
                                <i data-feather="log-out"></i>
                            </div>
                            Cerrar Sesión
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
        <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <nav class="sidenav shadow-right sidenav-light" aria-labelledby="menu">
                    <MenuComponent :options="menu"></MenuComponent>
                    <div class="sidenav-footer">
                        <div class="sidenav-footer-content">
                            <div class="sidenav-footer-subtitle">Inicio Sesion Como :</div>
                            <div class="sidenav-footer-title">{{ user.name }}</div>
                        </div>
                    </div>
                </nav>
            </div>
            <div id="layoutSidenav_content">
                <!--RENDERIZADO -->
                <router-view></router-view>
                <!-- END -->
                <footer class="footer mt-auto footer-light">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6 small">
                                Copyright &copy; Servisuministros JG S.A.S 2024
                            </div>
                            <div class="col-md-6 text-md-right small">
                                <a href="#!">Privacy Policy</a>
                                &middot;
                                <a href="#!">Terms &amp; Conditions</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>
<script>
import "./../../assets/vendor/sb-admin-pro/dist/css/styles.css"

import MenuComponent from "./content/menu";
import $ from 'jquery';
import 'bootstrap';

export default {
    components : {MenuComponent},
    mounted() {
        this.$nextTick(function () {
            // Enable Bootstrap tooltips via data-attributes globally
            $('[data-toggle="tooltip"]').tooltip();
            // Enable Bootstrap popovers via data-attributes globally
            $('[data-toggle="popover"]').popover();

            $(".popover-dismiss").popover({
                trigger: "focus",
            });
        });

        // Add active state to sidbar nav links
        var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
        $("#layoutSidenav_nav .sidenav a.nav-link").each(function () {
            if (this.href === path) {
                $(this).addClass("active");
            }
        });
        // Toggle the side navigation
        $("#sidebarToggle").on("click", function (e) {
            e.preventDefault();
            $("body").toggleClass("sidenav-toggled");
        });
        // Activate Feather icons
        //feather.replace();
        // Activate Bootstrap scrollspy for the sticky nav component
        $("body").scrollspy({
            target: "#stickyNav",
            offset: 82,
        });
        // Scrolls to an offset anchor when a sticky nav link is clicked
        $('.nav-sticky a.nav-link[href*="#"]:not([href="#"])').click(function () {
            if (
                location.pathname.replace(/^\//, "") ==
                this.pathname.replace(/^\//, "") &&
                location.hostname == this.hostname
            ) {
                var target = $(this.hash);
                target = target.length
                    ? target
                    : $("[name=" + this.hash.slice(1) + "]");
                if (target.length) {
                    $("html, body").animate(
                        {
                            scrollTop: target.offset().top - 81,
                        },
                        200
                    );
                    return false;
                }
            }
        });
        // Click to collapse responsive sidebar
        $("#layoutSidenav_content").click(function () {
            const BOOTSTRAP_LG_WIDTH = 992;
            if (window.innerWidth >= BOOTSTRAP_LG_WIDTH) {
                return;
            }
            if ($("body").hasClass("sidenav-toggled")) {
                $("body").toggleClass("sidenav-toggled");
            }
        });
        // Init sidebar
        let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, "$1");

        if (activatedPath) {
            activatedPath = activatedPath[0];
        } else {
            activatedPath = "index.html";
        }

        let targetAnchor = $('[href="' + activatedPath + '"]');
        let collapseAncestors = targetAnchor.parents(".collapse");

        targetAnchor.addClass("active");

        collapseAncestors.each(function () {
            $(this).addClass("show");
            $('[data-target="#' + this.id + '"]').removeClass("collapsed");
        });
    },
    data() {
        return {
            user_icon: ''
        }
    },
    methods: {
        async logout() {
            try {
                this.LoaderSpinnerShow();
                await this.$store.dispatch("logout");
                this.LoaderSpinnerHide();
                this.$router.push({path: '/login'});
                this.$store.commit('SET_LAYOUT', 'login-layout');
            } catch (error) {
                this.LoaderSpinnerHide();
                console.error(error);
            }
        }
    },
    computed: {
        menu() {
            return this.$store.state.menu;
        },
        user() {
            return this.$store.state.user;
        },
        campus(){
            return this.$store.state.campus;
        }
    },
};
</script>
